// Generated by Framer (d5ca742)

import { addFonts, ComponentViewportProvider, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import IcArrowLeft20px from "./yty0v7eyT";
const IcArrowLeft20pxFonts = getFonts(IcArrowLeft20px);

const enabledGestures = {qHHTWi9kj: {hover: true, pressed: true}};

const serializationHash = "framer-JV8f4"

const variantClassNames = {qHHTWi9kj: "framer-v-1i41qzy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "qHHTWi9kj", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1i41qzy", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"qHHTWi9kj"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(255, 255, 255, 0.12)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-1b444440-6e98-401c-93cc-651f6c08f890, rgb(20, 20, 31))", borderBottomLeftRadius: 40, borderBottomRightRadius: 40, borderTopLeftRadius: 40, borderTopRightRadius: 40, ...style}} variants={{"qHHTWi9kj-hover": {"--border-color": "rgba(255, 255, 255, 0.24)"}, "qHHTWi9kj-pressed": {"--border-color": "var(--token-52b9f1ec-8a5c-4a68-9b06-4560bba78883, rgb(255, 255, 255))"}}} {...addPropertyOverrides({"qHHTWi9kj-hover": {"data-framer-name": undefined}, "qHHTWi9kj-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><ComponentViewportProvider height={20} width={"20px"} y={((componentViewport?.y || 0) + (18 + ((((componentViewport?.height || 56) - 36) - 20) / 2)))}><motion.div className={"framer-1x2p4zz-container"} layoutDependency={layoutDependency} layoutId={"GuGu04qIu-container"}><IcArrowLeft20px height={"100%"} id={"GuGu04qIu"} layoutId={"GuGu04qIu"} style={{height: "100%", width: "100%"}} variant={"P5l8WLajZ"} width={"100%"}/></motion.div></ComponentViewportProvider></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-JV8f4.framer-1forc7a, .framer-JV8f4 .framer-1forc7a { display: block; }", ".framer-JV8f4.framer-1i41qzy { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 18px; position: relative; width: min-content; }", ".framer-JV8f4 .framer-1x2p4zz-container { flex: none; height: 20px; position: relative; width: 20px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-JV8f4.framer-1i41qzy { gap: 0px; } .framer-JV8f4.framer-1i41qzy > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-JV8f4.framer-1i41qzy > :first-child { margin-left: 0px; } .framer-JV8f4.framer-1i41qzy > :last-child { margin-right: 0px; } }", ".framer-JV8f4[data-border=\"true\"]::after, .framer-JV8f4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 56
 * @framerIntrinsicWidth 56
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"HPl7uq4RT":{"layout":["auto","auto"]},"RjIpN5DD7":{"layout":["auto","auto"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerfRRq0LJ34: React.ComponentType<Props> = withCSS(Component, css, "framer-JV8f4") as typeof Component;
export default FramerfRRq0LJ34;

FramerfRRq0LJ34.displayName = "button-56px-ic-arrow-left-on-dark-bg";

FramerfRRq0LJ34.defaultProps = {height: 56, width: 56};

addFonts(FramerfRRq0LJ34, [{explicitInter: true, fonts: []}, ...IcArrowLeft20pxFonts], {supportsExplicitInterCodegen: true})